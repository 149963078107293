import { graphql } from "gatsby";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { renderDynamicComponents } from "../components";
import Button from "../components/button";
import { getTeamMemberLink } from "../helper/link";
import { getValue } from "../helper/translation";
import Breadcrumb from "../modules/breadcrumb";
import Layout from "../modules/layout";
import News from "../modules/news";
import SEO from "../modules/seo";

const TeamOverview = ({ data: { allContentfulTeamMember }, pageContext }) => {
  const teamMembers = allContentfulTeamMember.edges.map(edge => edge.node);
  const {
    name,
    contentComponents,
    node_locale,
    settings,
    breadcrumbs,
    showBreadcrumbNavigation,
    showNews,
  } = pageContext;
  const siteSettings = settings;
  const { microcopy } = siteSettings;

  return (
    <Layout siteSettings={siteSettings}>
      <SEO
        title={`${siteSettings.siteName} - ${name}`}
        description={siteSettings.siteDescription}
        author={siteSettings.siteAuthor}
      />
      <Breadcrumb
        visible={showBreadcrumbNavigation}
        breadcrumbs={breadcrumbs}
        locale={node_locale}
      ></Breadcrumb>
      <div className="teamOverview">
        {contentComponents &&
          renderDynamicComponents(contentComponents, node_locale)}
        <Container fluid as="section">
          <Row className="team-members">
            {teamMembers
              .sort((a, b) =>
                a.name
                  .split(" ")
                  .pop()
                  .localeCompare(b.name.split(" ").pop())
              )
              .map(item => (
                <Col
                  xl={3}
                  lg={4}
                  sm={6}
                  xs={12}
                  className="team-member d-flex flex-xs-row flex-sm-column align-items-center align-items-sm-start"
                >
                  <img
                    alt={item.name}
                    src={item.photo.imagePortrait.fluid.src}
                    className="team-member__image d-block"
                  />
                  <div>
                    <div className="font-weight-bold">{item.name}</div>
                    <div>{item.jobTitle}</div>
                    <br />
                    <div>
                      <a href={`tel:${item.telephone}`}>{item.telephone}</a>
                    </div>
                    <div>
                      <a href={`mailto:${item.email}`}>{item.email}</a>
                    </div>
                    <Button
                      type="quaternary"
                      text={getValue("actions.moreInfo", microcopy)}
                      className="team-member__more"
                      link={getTeamMemberLink(item.name, node_locale)}
                    />
                  </div>
                </Col>
              ))}
          </Row>
        </Container>
      </div>
      <News node_locale={node_locale} settings={settings} visible={showNews} />
    </Layout>
  );
};

export const pageQuery = graphql`
  query($node_locale: String!) {
    allContentfulTeamMember(filter: { node_locale: { eq: $node_locale } }) {
      edges {
        node {
          name
          jobTitle
          email
          telephone
          biography {
            biography
          }
          photo {
            imagePortrait {
              fluid {
                base64
                aspectRatio
                src
                srcSet
                sizes
                srcWebp
                srcSetWebp
              }
            }
          }
        }
      }
    }
  }
`;

export default TeamOverview;
